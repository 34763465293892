<template>
  <div class="px-2 pt-2 pb-0" v-if="result.items.length">
    <b-card class="p-0 mb-0" no-body>
      <b-card-body class="p-2">

        <b-media v-for="(item, index) in getFilterByNonBundle" :key="`${index}-${item.product.name}`" right-align
          vertical-align="top" class="text-left p-0" :class="result.items.length > 1 ? 'mb-2' : ''">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="h6 text-dark mb-0">
              {{ item.product && item.product.name || '-' }}
            </h4>
            <div class="align-self-center ml-1" :id="`tour-delete-cart-${index}`">
              <b-button id="button--remove__cart" class="bg-white border-rounded p-0"
                style="box-shadow: 0px 7.2px 14.4px rgba(0, 0, 0, 0.06);height:32px;width:32px"
                @click="removeCart(item.uuid)">
                <feather-icon class="text-danger" icon="XIcon" />
              </b-button>
            </div>
          </div>
          <h6 class="text-dark size24">
            {{ item.total | formatAmount }} <span v-if="item.discount_total > 0"
              class="text-dark text-darken-5 font-weight-light size12" style="text-decoration: line-through">{{
              item.sub_price | formatAmount }}</span>
            <span v-if="item.discount_total > 0">&nbsp;<b-badge variant="danger" class="border-15 px-1 size12"
                style="padding-top: 5px;padding-bottom: 5px;">Diskon</b-badge></span>
          </h6>
          <div class="d-flex justify-content-between">
            <b-button id="button--edit__cart" size="sm" variant="outline-primary" class="size12"
              @click="editItem(item.product.uuid, item, item.uuid)">
              <b-spinner v-if="isLoadingEdit && item.uuid === id" small variant="primary"
                style="margin-right: 0.5rem;" />
              <feather-icon v-else size="12" icon="Edit2Icon" style="margin-right: 0.5rem;" />
              <span>Ubah rincian</span>
            </b-button>
            <div class="spinner__button spinner--cart__left">
              <div id="decrease" class="value-button text-dark font-weight-bolder" value="Decrease Value"
                @click="decreaseValue(index, item.uuid)">
                -
              </div>
              <input :id="`number-${index}`" class="qty text-dark font-weight-bolder" type="number"
                :value="item.qty || 0" @input="changeValue(index, item.uuid)">
              <div id="increase" class="value-button text-dark font-weight-bolder" value="Increase Value"
                @click="increaseValue(index, item.uuid)">
                +
              </div>
            </div>
          </div>
        </b-media>
        <!-- Bundle -->
        <b-media v-for="(item, index) in getFilterDataByBundle" :key="`${index}-${item}`" right-align
          vertical-align="top" class="text-left p-0" :class="getFilterDataByBundle.length > 1 ? 'mb-3' : ''">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="h6 text-dark mb-0">
              {{ item[0].bundle.name }}
            </h4>
            <div class="align-self-center ml-1">
              <b-button @click="handleRemoveBundle(item)" id="button--remove__cart" class="bg-white border-rounded p-0"
                style="box-shadow: 0px 7.2px 14.4px rgba(0, 0, 0, 0.06);height:32px;width:32px">
                <feather-icon class="text-danger" icon="XIcon" />
              </b-button>
            </div>
          </div>
          <h6 class="text-dark size24">
            {{ item[0].bundle.price | formatAmount }}
            <!-- <span>&nbsp;<b-badge variant="danger" class="border-15 px-1 size12"
              style="padding-top: 5px;padding-bottom: 5px;">Diskon</b-badge></span> -->
          </h6>
          <div class="d-flex justify-content-between">
            <b-button id="button--edit__cart" size="sm" variant="outline-info" class="size12"
              @click="handleDetailBundle(index)">
              <feather-icon size="12" icon="HelpCircleIcon" style="margin-right: 0.5rem;" />
              <span>Rincian Bundle</span>
            </b-button>
          </div>
        </b-media>
      </b-card-body>
    </b-card>
    <!-- <ModalEditProduct /> -->
    <b-modal id="detail-bundle" size="lg" title="Detail Bundle" hide-footer centered>
      <section class="app-ecommerce-details" v-if="detailBundle && detailBundle.length">
        <b-alert v-height-fade.appear variant="danger" :show="true" class="mb-0">
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            Rincian item tidak bisa diubah karena merupakan bundle.
          </div>
        </b-alert>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
            <div class="d-flex align-items-center justify-content-center">
              <b-img width="210" :src="bundleImage" :alt="`Image of ${bundleName}`" class="product-img" fluid />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ bundleName }}</h4>

            <!-- Product Brand -->
            <!-- <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ merchantName }}
              </b-link>
            </b-card-text> -->

            <!-- Price -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1 text-primary">
                {{ bundlePrice | formatAmount }}
              </h4>
              <b-card-text class="border-left pl-1">Available - <span class="text-success">In stock</span></b-card-text>
            </div>

            <!-- Avability -->

            <!-- Product Description -->
            <div class="table-responsive-sm">
              <table class="table b-table table-fixed">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Nama</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, keyBundle) in detailBundle" :key="`${keyBundle}-${item.bundle.name}`">
                    <td><b-img rounded="" width="50" :src="item.product.image_url"
                        :key="`${keyBundle}-${item.bundle.name}`" />
                    </td>
                    <td>{{ item.product.name || '-' }}</td>
                    <td>{{ item.qty || 0 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BButton, BCard, BCardBody, BMedia, BSpinner, BBadge, BModal, BImg, BLink, BRow, BCol, BCardText, BAlert
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

// import ModalEditProduct from '@/components/Transaction/Modal/EditProduct.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BMedia,
    BSpinner,
    BBadge,
    BModal,
    BImg,
    BLink,
    BRow,
    BCol,
    BCardText,
    AppCollapse,
    AppCollapseItem,
    BAlert
    // ModalEditProduct,
  },
  data() {
    return {
      result: this.$store.state.cart.resultCartActive,
      formPayload: [],
      isLoadingEdit: false,
      id: '',
      mainProps: {
        width: 175,
        height: 175,
        class: 'm1',
      },
      detailBundle: null
    }
  },
  computed: {
    bundleImage() {
      if (this.detailBundle.length > 0 && this.detailBundle[0].bundle) {
        return this.detailBundle[0].bundle.image;
      }
      return '-';
    },
    bundleName() {
      if (this.detailBundle.length > 0 && this.detailBundle[0].bundle) {
        return this.detailBundle[0].bundle.name;
      }
      return '-';
    },
    merchantName() {
      if (this.detailBundle.length > 0 && this.detailBundle[0].merchant) {
        return this.detailBundle[0].merchant.name;
      }
      return '-';
    },
    bundlePrice() {
      if (this.detailBundle.length > 0 && this.detailBundle[0].bundle) {
        return this.detailBundle[0].bundle.price;
      }
      return 0;
    },
    getFilterByNonBundle() {
      const itemsNonBundles = this.result.items.filter(item => item.bundle == null);
      return itemsNonBundles
    },
    getFilterDataByBundle() {
      const itemsWithBundles = this.result.items.filter(item => item.bundle !== null);

      const groupedByBundleName = itemsWithBundles.reduce((acc, item) => {
        const bundleName = item.bundle.name;
        if (!acc[bundleName]) {
          acc[bundleName] = [];
        }
        acc[bundleName].push(item);
        return acc;
      }, {});
      const result = Object.values(groupedByBundleName);
      return result
    },
  },
  watch: {
    '$store.state.cart.resultCartActive': function (value) {
      this.result = value
    },
    result: {
      handler(value) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < value.items.length; index++) {
          const element = value.items[index]
          this.formPayload.push(
            {
              qty: element.qty,
            },
          )
        }
      },
      deep: true,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  mounted() {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < this.result.items.length; index++) {
      const element = this.result.items[index]
      this.formPayload.push(
        {
          qty: element.qty,
        },
      )
    }
    setTimeout(() => {
      this.getFilterDataByBundle()
    }, 500);
  },
  methods: {
    handleDetailBundle(index) {
      this.detailBundle = this.getFilterDataByBundle[index]
      this.$bvModal.show('detail-bundle')
    },
    handleRemoveBundle(item) {
      this.$swal({
        title: 'Apakah kamu yakin?',
        text: 'Menghapus item ini akan menghapus bundle pada keranjang.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const uuid = item[0].uuid
        if (result.value && uuid) {
          this.$store.dispatch('cart/removeCartByCartUuid', {
            uuid: uuid,
            payload: '',
          }).then(res => {
            this.$swal({
              icon: 'success',
              title: 'Sukses!',
              text: 'Bundle berhasil dihapus',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            const item = res.data.data
            this.$store.commit('cart/setDiscountCart', item.discount_value)
            this.$store.commit('cart/setDiscountType', item.discount_type)
            this.$store.commit('cart/setDiscountTotal', item.discount_total)
            this.$store.commit('cart/setSubtotalCart', item.subtotal)
            this.$store.commit('cart/setTotalPrice', item.total)
            this.$store.commit('cart/setTotalBuy', item.items.length)
            this.$store.commit('cart/setCartUuid', item.uuid)
            this.$store.commit('cart/setResultCartActive', item)
            this.$store.commit('cashier/setProduct', item.items.length)
            localStorage.setItem('cartUuid', item.uuid)
            localStorage.setItem('cash_total_payment', item.total)
          }).catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })
        }
      })
    },
    updateQty: _.debounce((id, vm, index) => {
      // console.log(vm.formPayload[index].qty)
      vm.$store.dispatch('cart/updateQtyCartItem', {
        uuid: id,
        payload: {
          qty: vm.formPayload[index].qty,
        },
      }).then(result => {
        const item = result.data.data
        vm.$store.commit('cart/setDiscountCart', item.discount_value)
        vm.$store.commit('cart/setDiscountType', item.discount_type)
        vm.$store.commit('cart/setTotalPrice', item.total)
        vm.$store.commit('cart/setTotalBuy', item.items.length)
        vm.$store.commit('cart/setCartUuid', item.uuid)
        vm.$store.commit('cart/setResultCartActive', item)
        vm.$store.commit('cashier/setProduct', item.items.length)
        localStorage.setItem('cash_total_payment', item.total)
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    }, 300),
    async editItem(id, product, itemId) {
      this.id = itemId
      this.isLoadingEdit = true
      if (itemId === this.id) {
        await this.$store.dispatch('cashier/getData', {
          uuid: `/${id}`,
          params: '',
        }).then(result => {
          const item = result.data.data
          this.$store.commit('cart/setDetailCart', {
            item,
            product,
          })
          this.isLoadingEdit = false
          this.$store.commit('cart/setIsEdit', true)
          this.$bvModal.show('modal-add-product-to-cart-product')
        }).catch(err => {
          this.isLoadingEdit = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
      }
    },
    removeCart(id) {
      this.$swal({
        title: 'Apakah kamu yakin?',
        text: 'Apakah kamu yakin menghapus produk ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('cart/removeCartByCartUuid', {
            uuid: id,
            payload: '',
          }).then(res => {
            this.$swal({
              icon: 'success',
              title: 'Sukses!',
              text: 'Produk berhasil dihapus',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            const item = res.data.data
            this.$store.commit('cart/setDiscountCart', item.discount_value)
            this.$store.commit('cart/setDiscountType', item.discount_type)
            this.$store.commit('cart/setDiscountTotal', item.discount_total)
            this.$store.commit('cart/setSubtotalCart', item.subtotal)
            this.$store.commit('cart/setTotalPrice', item.total)
            this.$store.commit('cart/setTotalBuy', item.items.length)
            this.$store.commit('cart/setCartUuid', item.uuid)
            this.$store.commit('cart/setResultCartActive', item)
            this.$store.commit('cashier/setProduct', item.items.length)
            localStorage.setItem('cartUuid', item.uuid)
            localStorage.setItem('cash_total_payment', item.total)
          }).catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })
        }
      })
    },
    changeValue(index, id) {
      let value = parseInt(document.getElementById(`number-${index}`).value, 10)
      // eslint-disable-next-line no-restricted-globals
      value = isNaN(value) ? 0 : value
      document.getElementById(`number-${index}`).value = value
      this.formPayload[index].qty = value
      this.updateQty(id, this, index)
    },
    increaseValue(index, id) {
      let value = parseInt(document.getElementById(`number-${index}`).value, 10)
      // eslint-disable-next-line no-restricted-globals
      value = isNaN(value) ? 0 : value
      // eslint-disable-next-line no-plusplus
      value++
      document.getElementById(`number-${index}`).value = value
      this.formPayload[index].qty = value
      this.updateQty(id, this, index)
    },
    decreaseValue(index, id) {
      let value = parseInt(document.getElementById(`number-${index}`).value, 10)
      // eslint-disable-next-line no-restricted-globals
      value = isNaN(value) ? 0 : value
      // eslint-disable-next-line no-unused-expressions
      value < 1 ? value = 1 : ''
      // eslint-disable-next-line no-plusplus
      value--
      document.getElementById(`number-${index}`).value = value
      this.formPayload[index].qty = value
      this.updateQty(id, this, index)
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
